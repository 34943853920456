import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { CalendarIcon, KeyIcon } from '@heroicons/react/solid'

export default function Rooms() {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = () => {
        UserService.getRooms()
            .then((response) => {
                if (response.data.status === "success") {
                    if (Array.isArray(response.data.data.rooms)) {
                        setRooms(response.data.data.rooms);
                    }
                }
            });
    }

    const getDateFormat = (timestamp) => {
        return new Date(timestamp * 1000).toISOString().slice(0, 19).replace('T', ' ')
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Rooms</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Invite @wdev:matrix.org to your room.</p>
            </div>
            <div className="border-t border-gray-200">
                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <ul role="list" className="divide-y divide-gray-200">
                        {rooms.map((room) => (
                            <li key={room.name}>
                                <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-indigo-600 truncate">{room.name}</p>
                                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {room.joined ? (
                                                <p>
                                                    Joined on {getDateFormat(room.joined_at)}
                                                </p>
                                            ) : (
                                                <p>
                                                    Left on {getDateFormat(room.left_at)}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-2 sm:flex sm:justify-between">
                                        <div className="sm:flex">
                                            <p className="flex items-center text-sm text-gray-500">
                                                <KeyIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                {room.token}
                                            </p>
                                        </div>
                                        <div className="ml-2 flex-shrink-0 flex">
                                            {room.active ? (
                                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    Active
                                                </p>
                                            ) : (
                                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                    Inactive
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
