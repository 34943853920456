import { Link } from "react-router-dom";
import { HomeIcon } from '@heroicons/react/solid'
import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";

const userPages = [
    { name: 'Rooms', href: '/rooms'},
    { name: 'Account', href: '/account'}
]

export default function Nav(props) {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setUser(user);
        }
    }, []);

    const logOut = () => {
        AuthService.logout();
        setUser(undefined);
    };

    return (
        <nav className="flex justify-center" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link
                            to={"/"}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {user &&
                    userPages.map((page) => (
                        <li key={page.name}>
                            <div className="flex items-center">
                                <Link
                                    to={page.href}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                >
                                    {page.name}
                                </Link>
                            </div>
                        </li>
                    ))
                }
                {user ? (
                    <li>
                        <div className="flex items-center">
                            <Link
                                to={"/login"}
                                onClick={logOut}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                            >
                                Logout
                            </Link>
                        </div>
                    </li>
                ) : (
                    <>
                        <li>
                            <div className="flex items-center">
                                <Link
                                    to={"/login"}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                >
                                    Login
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <Link
                                    to={"/register"}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                >
                                    Sign Up
                                </Link>
                            </div>
                        </li>
                    </>
                )}
            </ol>
        </nav>
    )
}
