import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://api.matrix.pingtower.com/v1";

const getToken = () => {
    return axios.get(API_URL + "/auth/token", { headers: authHeader() });
}

const getAccount = () => {
    return axios.get(API_URL + "/account", { headers: authHeader() });
}

const getRooms = () => {
    return axios.get(API_URL + "/rooms", { headers: authHeader() })
}

export default {
    getToken,
    getAccount,
    getRooms
}