import React from "react";
const incentives = [
    {
        name: 'Customized notifications',
        description: "Send relevant content as soon as it's published.",
    },
    {
        name: 'Integrate with other services',
        description: "Enrich chats with content from external services.",
    },
    {
        name: 'Fast delivery',
        description:
            "Built for speed, powered by next-generation technology.",
    },
]

export default function Home() {
    return (
        <div>
            <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
                <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
                    <div className="max-w-3xl">
                        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                            Matrix bot service
                        </h2>
                        <p className="mt-4 text-gray-500">
                            Creating Matrix bots is super-easy, but you will need at least some skills at computer programming. In order for a bot to work, create an account, then connect it to your backend server via our API.
                        </p>
                    </div>
                    <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
                        {incentives.map((incentive) => (
                            <div key={incentive.name} className="sm:flex lg:block">
                                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                    <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                                    <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
