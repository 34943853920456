import axios from 'axios';


const API_URL = "https://api.matrix.pingtower.com/v1"

const register = (username, email, password) => {
    return axios.post(API_URL + "/auth/register", {
        username,
        password
    }, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "/auth/login", {
            username,
            password
        }, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
        .then((response) => {
            if (response.data.status === "success") {
                localStorage.setItem("user", JSON.stringify(response.data.data.user));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    register,
    login,
    logout,
    getCurrentUser
};