import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/Login";
import Home from "./components/Home";
import Account from "./components/Account";
import Rooms from "./components/Rooms";
import Nav from "./components/Nav";

const App = () => {
  return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div>
            <div className="my-12">
              <Nav/>
            </div>

            <div className="container mt-3">
              <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>} />
                <Route path="/rooms" element={<Rooms/>} />
                <Route path="/account" element={<Account/>} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
  );
};
export default App;